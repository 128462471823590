import { Component, Inject, OnInit, OnDestroy, viewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map as _map, cloneDeep, flatten, uniq, values, orderBy, uniqBy, remove } from 'lodash';
import { User, Role, UserConnectedServiceAndWorkingArea, Activity, Request, WorkingArea, Service, ActivityKindTypes } from '@shared/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import {
  ActivityItem,
  ActivityService,
  IActivityGroupRequestPayload,
  LabelService,
  RequestService,
  UserPromptsService,
  UserService,
  UtilitiesService
} from '@core/services';
import { NgSub } from 'ng-sub';
import { IObjectMap } from '@models/interface';
import { RoleTypes } from '@models/enum';
import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { isAfter, isValid } from 'date-fns';
import { Where } from '@shared/model/firestore';
import { take } from 'rxjs/operators';
import { MoveActivityComponent } from '@shared/components/move-activity/move-activity.component';
import { ActivityAddMode, ActivityItemDialogFormComponent } from '../activity-item-dialog-form/activity-item-dialog-form.component';
import { MatAccordion } from '@angular/material/expansion';

interface ActivityDialogInputData {
  user: User;
  role: Role;
  resources: IObjectMap<UserConnectedServiceAndWorkingArea>;
  request?: Request;
}

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss'],
})
export class ActivityDialogComponent extends ModalComponent implements OnInit, OnDestroy {

  protected accordion = viewChild.required(MatAccordion);

  public labels = this.labelService.defaultProvider();
  public user: User;
  public lastReadDate = new Date(0);
  public resourcesMap: IObjectMap<UserConnectedServiceAndWorkingArea> = {};
  public budgetActivities: Activity[];
  public workingAreaChips: WorkingArea[] = [];
  public serviceChips: Service[] = [];
  private sub = new NgSub();
  private subs = new NgSub();
  private seen = false;
  protected role: Role;
  public fetchingMore = true;
  public lastActivityRef: QueryDocumentSnapshot<any>;
  public activities: ActivityItem[] = [];
  protected request: Request;
  public creatorsMap: IObjectMap<User> = {};
  protected allRequestsOptions = {
    canSeeAllActivities: true,
    showAllRequests: false,
    allRequestCount: 0
  }
  public workingAreaStats: IObjectMap<number> = {}; // working area ids map to numbers
  public serviceStats: IObjectMap<number> = {};
  private opts: IActivityGroupRequestPayload;
  public filters: {
    workingAreaId: string;
    serviceId?: string;
  };
  public canReply: boolean;
  protected rowOptionBtn: { disabled: boolean; label: string } = { disabled: false, label: '' };
  private movePayload: { requests: Request[], activity: Activity, customerName: string } = {} as any;
  protected currentUserId: string;
  public hasOrgAdminRole: Boolean;
  public currentUser: User;
  protected remainingRequestCount: number;
  protected openAll = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ActivityDialogInputData,
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ActivityDialogComponent>,
    private labelService: LabelService,
    private activityService: ActivityService,
    private userService: UserService,
    private utilitiesService: UtilitiesService,
    private requestService: RequestService,
    private userPromptsService: UserPromptsService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  ngOnInit() {
    this.request = cloneDeep(this.data.request);
    this.user = cloneDeep(this.data.user);
    this.resourcesMap = cloneDeep(this.data.resources);
    this.role = cloneDeep(this.data.role);
    this.dialogRef.disableClose = true;
    this.canReply = this.utilitiesService.rolesMatch(RoleTypes.professional, RoleTypes.coordinator);
    this.sub.add(
      this.userService.getCurrentUser().subscribe(user => {
        this.currentUser = user;
        this.hasOrgAdminRole = this.currentUser.isOrgAdmin ? this.currentUser.isOrgAdmin : false;
      })
    )
    // Filters options set here
    this.opts = {
      kind: null,
      limit: 30,
      lastDocRef: this.lastActivityRef,
      requestId: this.request.id
    };

    this.labelService.getLabels('app-activity-dialog').then(r => this.labels = r.data);
    if (this.resourcesMap) {
      this.fetchData();
      if (this.user) this.creatorsMap[this.user.id] = this.user;
    } else {
      this.activityService.getUserConnectedWorkingAreasAndServices().then(res => {
        this.resourcesMap = res;
        this.fetchData();
        if (this.user) this.creatorsMap[this.user.id] = this.user;
      });
    }
    this.currentUserId = this.userService.getCurrentUserId();
    this.user.activityLastSeenByUsers = this.user?.activityLastSeenByUsers || {};

    if (this.user?.activityLastSeenByUsers[this.currentUserId]) {
      this.lastReadDate = this.user.activityLastSeenByUsers[this.currentUserId];
    }
  }

  private activitiesLoaded(): void {
    if (!this.seen) {
      this.seen = true;
      setTimeout(() => {
        this.activityService.updateLastSeenActivitiesForUser(this.user.id, new Date());
      }, 3000);
    }
  }

  private async fetchData() {
    const workingAreas = values(this.resourcesMap).map(m => m.workingArea);
    const services = flatten(values(this.resourcesMap).map(m => m.services));

    // default chips setup
    const isProfessional = this.utilitiesService.rolesMatch(RoleTypes.professional);
    this.workingAreaChips = isProfessional
      ? workingAreas
      : [];

    this.serviceChips = isProfessional
      ? this.resourcesMap[this.role?.workingAreaId].services
      : services;

    // default filters
    this.filters = {
      workingAreaId: this.role.workingAreaId,
      serviceId: isProfessional ? null : this.role.serviceId,
    };

    // fetch first default activities
    this.fetchActivities();
    if (this.user) this.getUserActivityCount();
  }

  private getUserActivityCount() {
    const where: Where[] = [['serviceId', 'in', values(this.resourcesMap)[0].userConnectedServices]];
    this.activityService.getActivitiesCounts(this.user.id, () => {
     //temp fix, enable after mifration viaible for
      // if (this.role.roleType == RoleTypes.excecutor) {
      //   where.push(['visibleFor.executor', '==', true])
      // }

      if (this.role.roleType == RoleTypes.servicePoint) {
        where.push(['visibleFor.servicePoint', '==', true])
      }
      return where;
    }).then(count => {
      this.allRequestsOptions.allRequestCount = count;
      this.allRequestsOptions.canSeeAllActivities = this.activities.length == count && !this.allRequestsOptions.showAllRequests;
    });
  }

  public fetchActivities(): void {
    console.log(this.opts)
    this.subs.add(
      this.activityService.getUserActivities(this.user.id, this.opts)
        .subscribe(acts => {
          this.processUsers(acts);
          this.lastActivityRef = acts.length === this.opts.limit ? acts[acts.length - 1]['__doc'] : null;
          this.activitiesLoaded();
        })
    )
  }

  private processUsers(activities: Activity[]) {
    const userIds: string[] = [];
    const repliedUsers = [];

    activities.forEach(act => {
      const createdBy = act.log.createdBy;
      const userId = act.userId;
      act.replies.map(r => repliedUsers.push(r.log.createdBy));
      if (!this.creatorsMap[createdBy] || !this.creatorsMap[userId]) {
        userIds.push(createdBy, userId);
      }

    })
    repliedUsers.forEach(ru => {
      if (!this.creatorsMap[ru]) userIds.push(ru);
    })

    if (userIds.length) this.fetchUsers(uniq(userIds), activities);
    else this.parseDataForView(activities);
  }

  private fetchUsers(userIds: string[], acts: Activity[]) {
    this.sub.add(
      this.userService.getUsersFromIdsNew(userIds).subscribe(users => {
        users.forEach(user => {
          this.creatorsMap[user.id] = user;
          this.parseDataForView(acts);
        })
      })
    )
  }

  private parseDataForView(acts: Activity[]) {
    const activities: ActivityItem[] = [];

    acts.forEach(a => {
      activities.push({
        activity: a,
        workingArea: this.resourcesMap[a.workareaId].workingArea,
        service: this.resourcesMap[a.workareaId].services.find(s => s.id === a.serviceId),
      });
    })
    this.activities = orderBy(uniqBy(activities.concat(this.activities), 'activity.id'), ['activity.date'], ['desc']);
    this.remainingRequestCount = this.allRequestsOptions.allRequestCount - this.activities.length;
    this.fetchingMore = false;
  }

  private updateStats(): void {
    values(this.resourcesMap).forEach(w => {
      this.workingAreaStats[w.workingArea.id] = -1;

      w.services.forEach(s => {
        this.serviceStats[s.id] = -1;
      });

      // grab all service stats here
      Promise.all(w.services.map(s => {
        return this.activityService.getUserActivitiesForServiceCount(s.id, this.user.id);
      })
      ).then(activities => {
        this.workingAreaStats[w.workingArea.id] = 0;

        for (let i = 0; i < w.services.length; i++) {
          const s = w.services[i];

          // number of activities per service
          const count = activities[i];

          this.serviceStats[s.id] = count;
          this.workingAreaStats[w.workingArea.id] += count;
        }
      });
    });
  }

  public onClickWorkingAreaChip(area: WorkingArea): void {
    this.activities = [];
    this.serviceChips = this.resourcesMap[area.id].services;
    this.lastActivityRef = null;

    this.filters = {
      workingAreaId: area.id,
      serviceId: null,
    };
    this.opts.workingareaId = this.filters.workingAreaId;
    this.opts.serviceId = null;
    
    this.fetchActivities();
  }

  public onClickServiceChip(service: Service): void {
    this.activities = [];
    this.lastActivityRef = null;

    this.filters = {
      workingAreaId: service.workingAreaId,
      serviceId: service.id,
    };

    this.opts.workingareaId = this.filters.workingAreaId;
    this.opts.serviceId = this.filters.serviceId;

    this.fetchActivities();
  }

  protected showAllRequestsChanged(value: boolean) {
    if (value) {
      this.updateStats();
      this.opts.requestId = '';
      this.opts.workingareaId = this.filters.workingAreaId;
      this.opts.serviceId = this.filters.serviceId;
    } else {
      this.opts.requestId = this.request.id;
      this.opts.workingareaId = '';
      this.opts.serviceId = '';
    }
    this.lastActivityRef = null;
    // this.subs.unsubscribe();
    this.activities = [];
    this.fetchActivities();
  }

  public getLocaleDate(d: Date, weekday?: boolean): string {
    const date = isValid(new Date(d.toString())) ? new Date(d.toString()) : null;

    if (date) {
      return weekday
        ? date.toLocaleDateString(undefined, { weekday: 'short' })
        : date.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' });
    } else {
      return '';
    }
  }

  public getUser(userId: string): User {
    return this.creatorsMap[userId];
  }

  protected rowOptionClicked(row: ActivityItem) {
    this.fetchCustomerRequests(row);
  }

  private async fetchCustomerRequests(row: ActivityItem) {
    let promise: Promise<Request[]>;
    const activity = row.activity;
    this.movePayload.activity = activity;
    const customer = await this.userService.getUserById(activity.userId).pipe(take(1)).toPromise();
    this.movePayload.customerName = customer.fullname
    if (this.role.roleType == RoleTypes.coordinator) {
      promise = this.requestService.getCustomerRequests(activity.userId, this.role.serviceId);
    } else if (this.role.roleType == RoleTypes.professional) {
      promise = this.requestService.getCustomerRequests(activity.userId, null, this.role.workingAreaId);
    }
    const requests = await promise;
    this.movePayload.requests = requests;
    this.rowOptionBtn.label = requests?.length ? `${this.labels.move} (${requests.length})` : this.labels.move;
    if (requests?.length) {
      if (requests.length == 1 && requests[0].id == activity.requestId) {
        this.rowOptionBtn.disabled = true;
        return;
      }
      this.rowOptionBtn.disabled = false;
    } else {
      this.rowOptionBtn.disabled = true;
    }
  }

  // public addReply(item: Activity) {
  //   const reply = JSON.parse(JSON.stringify(new ActivityReply()));

  //   if (!item.replies) {
  //     item.replies = [];
  //   }

  //   this.handleActivityReply(reply, item);
  // }

  // private handleActivityReply(reply: ActivityReply, item: Activity) {
  //   const replyId = reply.id;

  //   this.userPromptsService.showPromptDialogue(
  //     replyId ? this.labels.edit_reply : this.labels.add_reply,
  //     this.labels.activity_reply_body, {
  //     initialValue: reply.description,
  //     type: 'textarea',
  //     required: true
  //   }).then(async (desc: string) => {
  //     if (desc) {
  //       reply.description = desc;

  //       delete item['__doc'];
  //       await this.userService.updateActivityReply(reply, item, this.user.id);
  //       this.userPromptsService.showToast(
  //         reply.id ? this.labels.reply_updated : this.labels.reply_created
  //       );
  //     }
  //   });
  // }

  protected moveActivity() {
    this.userPromptsService.showDialogue(MoveActivityComponent, this.movePayload, null, null, { width: '500px' });
  }

  public editActivity(card: Activity): void {
    const payload = {
      role: cloneDeep(this.role),
      services: cloneDeep(this.resourcesMap[card.workareaId].services),
      activity: card,
      mode: ActivityAddMode.update,
      request: this.request
    };

    this.userPromptsService.showDialogue(
      ActivityItemDialogFormComponent, payload,
      (r: Activity) => {
        if (r) {
          delete r['__doc'];
          this.userService.updateActivityInUser(this.user.id, r);

          this.userPromptsService.showToast(
            this.labels._translate('activity_edited', { title: r.title })
          );
        }
      },
      true
    );
  }

  public deleteActivity(activity: Activity): void {
    this.userPromptsService.showConfirmDialogue(
      this.labels.confirm_delete_title, // Delete activity card
      this.labels.confirm_delete_message, // 'Are you sure wants delete this?'
      async (confirmed: boolean) => {
        if (confirmed) {
          const activityTitle = activity.title;
          const activityId = activity.id;

          await this.userService.deleteActivityFromUser(this.user.id, activityId);

          remove(this.activities, i => i.activity.id === activityId);
          this.userPromptsService.showToast(
            this.labels._translate('activity_deleted', { title: activityTitle })
          );
        }
      }
    );
  }

  public isUnRead(date: Date): boolean {
    return isAfter(date, this.lastReadDate);
  }

  protected fetchMoreActivities() {
    this.fetchingMore = true;
    this.opts.lastDocRef = this.lastActivityRef;
    this.fetchActivities();
  }
  
  protected getIcon(activity: Activity) {
    switch (activity.kind) {
      case ActivityKindTypes.note:
        return 'description';

      case ActivityKindTypes.contact_live:
        return 'home';

      case ActivityKindTypes.phonecall:
        return 'call';

      case ActivityKindTypes.contact_mail:
        return 'mail';

      case ActivityKindTypes.intake:
        return 'assignment_turned_in';

      case ActivityKindTypes.evaluation:
        return 'flag';

      case ActivityKindTypes.sale:
        return 'shopping_bag';

      case ActivityKindTypes.payment:
        return 'credit_card';

      default:
        return 'monitor';
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();

    this.subs.unsubscribe();
  }
}
