import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { ActivityItem, LabelService, UtilitiesService, ActivityService, UserPromptsService, UserService, IActivityGroupRequestPayload } from '@core/services';
import { RoleTypes } from '@models/enum';
import { IObjectMap } from '@models/interface';
import { User, WorkingArea, Service, UserConnectedServiceAndWorkingArea, Activity, ActivityReply, ActivityKindTypes, Role, Request } from '@models/model';
import { ActivityAddMode, ActivityItemDialogFormComponent } from '@shared/entry-components';
import { values, flatten, cloneDeep, remove, orderBy, uniqBy } from 'lodash';
import { NgSub } from 'ng-sub';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-global-activity-list',
  templateUrl: './global-activity-list.component.html',
  styleUrls: ['./global-activity-list.component.scss']
})
export class GlobalActivityListComponent implements OnInit, OnDestroy {
  @Input() user: User;
  @Input() resources: IObjectMap<UserConnectedServiceAndWorkingArea>;
  @Input() lastReadDate: Date;
  @Input() editMode: boolean;
  @Input() isGroup: boolean;
  @Input() role: Role = this.userService.getCurrentUserRole();
  @Input() request: Request;

  @Output() onAverageSumAmount = new EventEmitter<number>();
  @Output() onActivities = new EventEmitter<ActivityItem[]>();

  public labels = this.labelService.defaultProvider();
  public fetchingMore = false;
  public activities: ActivityItem[] = [];
  public workingAreaChips: WorkingArea[] = [];
  public serviceChips: Service[] = [];
  public workingAreaStats: IObjectMap<number> = {}; // working area ids map to numbers
  public serviceStats: IObjectMap<number> = {};
  public lastActivityRef: QueryDocumentSnapshot<any>;
  public creatorsMap: IObjectMap<User> = {};
  public filters: {
    workingAreaId: string;
    serviceId?: string;
  };
  protected allRequestsOptions = {
    canSeeAllActivities: true,
    showAllRequests: false,
    allRequestCount: 0
  }
  private resourcesMap: IObjectMap<UserConnectedServiceAndWorkingArea> = {};
  private sub = new NgSub();
  private subs = new NgSub();

  constructor(
    private labelService: LabelService,
    private utilitiesService: UtilitiesService,
    private activityService: ActivityService,
    private userPromptsService: UserPromptsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.user && !this.isGroup) {
      throw Error('User must be defined on init');
    }

    if (this.resources) {
      this.resourcesMap = this.resources;
      this.fetchData();
      if (this.user) this.fetchUser(this.user.id);
    } else {
      this.activityService.getUserConnectedWorkingAreasAndServices().then(res => {
        this.resourcesMap = res;

        this.fetchData();
        if (this.user) this.fetchUser(this.user.id);
      });
    }

    this.labelService.getLabels('app-global-activity-list').then(r => this.labels = r.data);
  }

  private async fetchData() {
    this.updateStats();

    const workingAreas = values(this.resourcesMap).map(m => m.workingArea);
    const services = flatten(values(this.resourcesMap).map(m => m.services));

    // default chips setup
    const isProfessional = this.utilitiesService.rolesMatch(RoleTypes.professional);
    this.workingAreaChips = isProfessional
      ? workingAreas
      : [];

    this.serviceChips = isProfessional
      ? this.resourcesMap[this.role?.workingAreaId].services
      : services;

    // default filters
    this.filters = {
      workingAreaId: this.role.workingAreaId,
      serviceId: isProfessional ? null : this.role.serviceId,
    };

    // fetch first default activities
    this.fetchActivities();
  }

  private updateStats(): void {
    if (this.isGroup) {
      return;
    }

    values(this.resourcesMap).forEach(w => {
      this.workingAreaStats[w.workingArea.id] = -1;

      w.services.forEach(s => {
        this.serviceStats[s.id] = -1;
      });

      // grab all service stats here
      combineLatest(w.services.map(s => {
        return this.activityService.getUserActivitiesForService(s.id, this.user.id);
      })).subscribe(activities => {
        this.workingAreaStats[w.workingArea.id] = 0;

        for (let i = 0; i < w.services.length; i++) {
          const s = w.services[i];

          // number of activities per service
          const acts = activities[i];

          this.serviceStats[s.id] = acts.length;
          this.workingAreaStats[w.workingArea.id] += acts.length;
        }
      });
    });
  }

  public onClickWorkingAreaChip(area: WorkingArea): void {
    this.serviceChips = this.resourcesMap[area.id].services;
    this.lastActivityRef = null;

    this.filters = {
      workingAreaId: area.id,
      serviceId: null,
    };

    this.fetchActivities();
  }

  public onClickServiceChip(service: Service): void {
    this.lastActivityRef = null;

    this.filters = {
      workingAreaId: service.workingAreaId,
      serviceId: service.id,
    };

    this.fetchActivities();
  }

  public addReply(item: Activity) {
    const reply = JSON.parse(JSON.stringify(new ActivityReply()));

    if (!item.replies) {
      item.replies = [];
    }

    this.handleActivityReply(reply, item);
  }

  private handleActivityReply(reply: ActivityReply, item: Activity) {
    const replyId = reply.id;

    this.userPromptsService.showPromptDialogue(
      replyId ? this.labels.edit_reply : this.labels.add_reply,
      this.labels.activity_reply_body, {
      initialValue: reply.description,
      type: 'textarea',
      required: true
    }).then(async (desc: string) => {
      if (desc) {
        reply.description = desc;

        delete item['__doc'];
        await this.userService.updateActivityReply(reply, item, this.user.id);
        this.userPromptsService.showToast(
          reply.id ? this.labels.reply_updated : this.labels.reply_created
        );
      }
    });
  }

  public editReply(reply: ActivityReply, item: Activity) {
    this.handleActivityReply(reply, item);
  }

  public editActivity(card: Activity): void {
    const payload = {
      role: cloneDeep(this.role),
      services: cloneDeep(this.resourcesMap[card.workareaId].services),
      activity: card,
      mode: ActivityAddMode.update,
      request: this.request
    };

    this.userPromptsService.showDialogue(
      ActivityItemDialogFormComponent, payload,
      (r: Activity) => {
        if (r) {
          delete r['__doc'];
          this.userService.updateActivityInUser(this.user.id, r);

          this.userPromptsService.showToast(
            this.labels._translate('activity_edited', { title: r.title })
          );
        }
      },
      true
    );
  }

  public deleteActivity(activity: Activity): void {
    this.userPromptsService.showConfirmDialogue(
      this.labels.confirm_delete_title, // Delete activity card
      this.labels.confirm_delete_message, // 'Are you sure wants delete this?'
      async (confirmed: boolean) => {
        if (confirmed) {
          const activityTitle = activity.title;
          const activityId = activity.id;

          await this.userService.deleteActivityFromUser(this.user.id, activityId);

          remove(this.activities, i => i.activity.id === activityId);
          this.userPromptsService.showToast(
            this.labels._translate('activity_deleted', { title: activityTitle })
          );
        }
      }
    );
  }

  private fetchUser(userId: string): void {
    if (userId && this.creatorsMap[userId] === undefined) {
      this.creatorsMap[userId] = null; // to prevent is from being undefined anymore

      this.userService.getUserById(userId).pipe(takeUntil(this.sub)).subscribe(user => {
        this.creatorsMap[userId] = user;
      });
    }
  }

  public fetchActivities(): void {
    if (this.fetchingMore) {
      return;
    }

    if (!this.lastActivityRef) {
      this.activities = [];
      this.subs.unsubscribe();
    }

    this.subs = new NgSub();

    const opts: IActivityGroupRequestPayload = {
      // workingareaId: this.filters.workingAreaId,
      // serviceId: this.filters.serviceId,
      kind: null,
      limit: 30,
      lastDocRef: this.lastActivityRef,
    };

    if (this.request) {
      opts.requestId = this.allRequestsOptions.showAllRequests ? null : this.request.id;
    } else {
      opts.workingareaId = this.filters.workingAreaId;
      opts.serviceId = this.filters.serviceId;

    }

    const obs$ = this.isGroup
      ? this.activityService.getActivities(opts)
      : this.activityService.getUserActivities(this.user.id, opts);

    this.fetchingMore = true;
    const sub = obs$.subscribe({
      next: acts => {
        this.lastActivityRef = acts.length === opts.limit ? acts[acts.length - 1]['__doc'] : null;
        const activities: ActivityItem[] = [];

        for (const a of (acts || [])) {
          this.fetchUser(a.log.createdBy);
          this.fetchUser(a.userId);

          activities.push({
            activity: a,
            workingArea: this.resourcesMap[a.workareaId].workingArea,
            service: this.resourcesMap[a.workareaId].services.find(s => s.id === a.serviceId),
          });

          // fetch users for replies as well
          for (const r of (a.replies || [])) {
            this.fetchUser(r.log.createdBy);
          }
        };

        this.fetchingMore = false;
        // @ts-ignore
        this.activities = orderBy(uniqBy(activities.concat(this.activities), 'activity.id'), ['activity.date'], ['desc']);
        if (this.user) {
          this.activityService.getActivitiesCounts(this.user.id).then(count => {
            this.allRequestsOptions.allRequestCount = count;
            this.allRequestsOptions.canSeeAllActivities = this.activities.length == count && !this.allRequestsOptions.showAllRequests;
            if (this.allRequestsOptions.showAllRequests) this.openNoticeDialog();
          });
        }
        this.onActivities.emit(this.activities);
        this.getAverageSumAmount();
      },
      error: e => console.error(e),
    });

    this.subs.add(sub);
  }

  private openNoticeDialog() {
    if (this.activities.length < this.allRequestsOptions.allRequestCount) {
      this.userPromptsService.showAlertDialogue(this.labels.notice, this.labels.you_are_not_permitted_to_see_some_activities);
    }
  }

  public deleteActivityReply(reply: ActivityReply, activity: Activity) {
    this.userPromptsService.showConfirmDialogue(
      this.labels.confirm_action,
      this.labels.sure_to_delete_reply,
      async (sure: boolean) => {
        if (sure) {
          activity.replies = activity.replies.filter(r => r && r.id !== reply.id);
          delete activity['__doc'];

          await this.userService.updateActivityInUser(this.user.id, activity);
          this.userPromptsService.showToast(this.labels.activity_reply_deleted);
        }
      }
    );
  }

  private getAverageSumAmount(): void {
    let amount = 0;

    if (this.activities?.length > 0) {
      const payments: Activity[] = [];
      const sales: Activity[] = [];

      this.activities.map(a => a.activity).forEach(a => {
        if (a.kind === ActivityKindTypes.sale) {
          sales.push(a);
        } else if (a.kind === ActivityKindTypes.payment) {
          payments.push(a);
        }
      });

      const paymentSum = payments.reduce((prev, cur) => {
        return prev + (cur.amount || 0);
      }, 0);

      const salesSum = sales.reduce((prev, cur) => {
        return prev + (cur.amount || 0);
      }, 0);

      amount = salesSum - paymentSum;
    }

    this.onAverageSumAmount.emit(amount);
  }

  protected showAllRequestsChanged() {
    this.lastActivityRef = null;
    this.fetchActivities();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();

    this.subs.unsubscribe();
  }
}
