<app-modal>

    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">Activity Visibility</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 1em;">
        @if (isLoading) {
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        @if (activity.visibleFor) {
        <div fxLayout="column" fxLayoutGap="15px">
            <mat-slide-toggle [(ngModel)]="activity.visibleFor.servicePoint">{{labels.service_point}}</mat-slide-toggle>
            <mat-slide-toggle [(ngModel)]="activity.visibleFor.executor">{{labels.executor}}</mat-slide-toggle>
            <mat-slide-toggle [disabled]="true" [(ngModel)]="activity.visibleFor.coordinator">{{labels.coordinator}}</mat-slide-toggle>
            <mat-slide-toggle [disabled]="true" [(ngModel)]="activity.visibleFor.professional">{{labels.professional}}</mat-slide-toggle>
        </div>
        }
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px">
        <button mat-raised-button [disabled]="isLoading" (click)="save()">{{labels.save}}</button>
    </div>
</app-modal>